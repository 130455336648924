<template>
	<div class="animated fadeIn">
		<b-card class="pb-5">
			<div class="display-table full-width mb-2">
				<strong class="card-title">{{$route.meta.label}}</strong>
			</div>
			<b-row>
        <ResponseAlert ref="response" />
        <b-col class="mb-2">
          <div class="m-auto" style="width: 40%;">
            <b-img :src="getUrlOfFile(models.thumbnail)" rounded fluid class="mb-2"/>
          </div>
          <main class="mt-3">
            <div class="d-flex align-items-center">
              <h2 class="mr-4 mt-2">{{models.title}}</h2>
              <b-badge v-if="models.is_breaking_news" variant="danger">{{models.is_breaking_news && 'BREAKING NEWS'}}</b-badge>
            </div>

            <div class="d-flex align-items-center">
              <b-badge class="text-uppercase mb-2 mr-3" :variant="models.status === 'publish' ? 'success' : models.status === 'draft' || models.status === 'reject' ? 'danger' : 'warning'">{{models.status}}</b-badge>
              <p class="mt-2">{{models.category}}</p>
            </div>

            <h6>{{models.created_by}}</h6>
            <h6>{{moment(models.date).format('dddd, DD MMMM YYYY HH:mm')}}</h6>
            <h6>Total View: {{models.total_read}}</h6>
            <h6>News Type: <span class="text-uppercase">{{models.type}}</span></h6>

            <hr>

            <div v-if="models.galleries.length > 0" class="items-wrapper">
              <div v-for="(file, index) in models.galleries" :key="index" class="item-wrapper">
                <b-img :src="getUrlOfFile(file.image)" class="mb-2" alt="image-items"/>
              </div>
            </div>

            <div v-if="models.video !== ''" class="items-wrapper">
              <video ref="frame" v-if="isMp4" height="240" controls :src="models.video">
							</video>
              <iframe ref="frame" v-else width="500" height="340" :src="models.video">
              </iframe>
            </div>

            <div id="content" v-html="models.description">
            </div>

          </main>
        </b-col>
      </b-row>
      <template #footer>
        <div class="d-flex justify-content-center">
          <b-button type="button" variant="light" block class="rounded" @click="$router.push({name: config.uri})">
            Cancel
          </b-button>
        </div>
      </template>
		</b-card>
	</div>
</template>
<script>
	import getYoutubeId from 'get-youtube-id'
  import moment from 'moment'
	import api from '@/utils/api.js'
	import ResponseAlert from '@/components/response_alert';
  import {
		getUrlOfFile
	} from '@/utils/helpers.js';

	export default {
		name: 'Detailnews',
    components: {
      ResponseAlert
    },
		data() {
			return {
				config: {
					api: api.news,
					uri: this.$route.meta.permission.link,
				},
				models: {
          category: '',
          created_by: '',
          date: '',
          description: '',
          galleries: [],
          is_breaking_news: null,
          status: '',
          thumbnail: null,
          title: '',
          total_read: '',
          type: '',
          video: ''
        },
        moment
			}
		},
		created() {
			const _ = this;
			_.getById();
		},
    computed: {
      isMp4(){
				return this.models.video.substring(this.models.video.lastIndexOf(".") + 1) === 'mp4'
			}
    },
		methods: {
      getUrlOfFile(file) {
				return getUrlOfFile(file);
			},
      renderCarousel(galleries) {
        const images = galleries.map(gallery => {
            return `
            <div class="item-wrapper swiper">
              <img src="${gallery.image}" alt="gallery">
            </div>
            `
        });
				return `
			<div class="items-wrapper swiper">
				${images}
			</div>
			`
			},
      getById() {
				const _ = this;
				if (_.$route.params.id) {
					_.$axios.get(`${api.news}/${_.$route.params.id}`)
						.then(res => {
							const { data } = res.data;

              _.models.category = data.category.name
              _.models.created_by = `${data.created_by.first_name} ${data.created_by.last_name}`
              _.models.date = data.date
              _.models.description = data.description
              _.models.galleries = data.galleries
              _.models.is_breaking_news =  data.is_breaking_news
              _.models.status = data.status
              _.models.thumbnail = data.thumbnail
              _.models.title = data.title
              _.models.total_read = data.total_read
              _.models.type = data.type
              
              const haveSlider = data.description.split('[IMAGE_SLIDE]').length > 1
							
              if(haveSlider) {
                _.models.description = data.description.replace('[IMAGE_SLIDE]', this.renderCarousel(data.galleries))
              }

              if(data.video) {
                _.models.video = data.video
                if(_.models.video.toLowerCase().includes('youtube.com')) {
                  _.models.video = 'https://www.youtube.com/embed/' + getYoutubeId(_.models.video)
                } else {
                  _.models.video = this.models.video
                }
              }

						}).catch(() => _.$router.push({
							name: 'page404'
						}));
				}
			},
		},
	}
</script>
<style>
.items-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 20px 0;
  margin-bottom: 20px;
}
.items-wrapper.swiper {
  width: 60%;
  display: -webkit-box;
  margin: auto;
}

.items-wrapper img:last-child {
  margin-right: 0;
}

.item-wrapper {
  margin-right: 25px;
  height: 150px;
}
.item-wrapper.swiper {
  margin-right: unset;
  height: 300px;
  width: 90%;
}

.item-wrapper img {
  flex: 0 0 auto;
  height: 100%;
}
#content .item-wrapper.swiper img {
  flex: 0 0 auto;
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: unset;
  margin: unset;
}

.items-wrapper::-webkit-scrollbar {
  height: 5px;      
  border-radius: 15px;
}

.items-wrapper::-webkit-scrollbar-track {
  border-radius: 15px;    /* width of the entire scrollbar */
  background: lightgray;        /* color of the tracking area */
}

.items-wrapper::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid rgb(180, 180, 180);  /* creates padding around scroll thumb */
}

#content img {
    width: 50%;
    display: block;
    margin: auto;
}
</style>